import { useAtomValue } from "jotai";
import { useParams } from "react-router-dom";
import { TwitchEmbed } from "react-twitch-embed";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const LivestreamTab = () => {
  const darkMode = useAtomValue(darkModeAtom);
  const { isMobile } = useMediaQueryBreakpoint();
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);

  if (!userData?.profile?.twitch_username) {
    return null;
  }

  return (
    <TwitchEmbed
      channel={userData.profile.twitch_username}
      autoplay
      withChat
      width="100%"
      height={isMobile ? "600px" : "100vh"}
      darkMode={darkMode ? true : false}
    />
  );
};
