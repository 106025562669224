// Contains methods to create a valid URL for a given page/API.
import { compile } from "path-to-regexp";
import queryString from "query-string";
import { SCREENS } from "../../routes";
import { BookingQueryParamKeys } from "../../stories/screens/BookingsScreen/hooks";
import {
  PAGE_VALUES,
  PAGE_VALUES_TO_QUERY_VALUES,
} from "../../stories/screens/MessagesV2Screen/atoms";
import { setInitialBookingParameters } from "../actions/generateBookingStore";
import { setUpCart, SetUpCartPayload } from "../actions/shoppingCart";
import type { AppDispatch } from "../index";
import { MusoSelectionTab } from "../models/muso";
import { RecordingSessionQueryParams } from "../models/recording";
import { FinancialMethod, PaymentRedirectType } from "../models/transaction";
import {
  APPLY_TRANSACTION_PROMO_CODE,
  CALCULATE_PRODUCT_TAX,
  CREATE_TRANSACTION_PAYMENT_INTENT,
  GET_PROJECT,
  GET_PROJECT_FILES,
  GET_PROJECT_HISTORY,
  SHARE_TRANSACTION,
  UPDATE_TRANSACTION,
} from "./routes";
import { BookingParameters } from "./transactions";

export const getUpdateTransactionAPIRoute = (transactionId: number) =>
  compile(UPDATE_TRANSACTION)({ transaction_id: transactionId });

export const getCreateTransactionPaymentIntentRoute = (
  transactionCode: string,
) =>
  compile(CREATE_TRANSACTION_PAYMENT_INTENT)({
    transaction_code: transactionCode,
  });

export const getApplyTransactionPromoCodeRoute = (transactionCode: string) =>
  compile(APPLY_TRANSACTION_PROMO_CODE)({
    transaction_code: transactionCode,
  });

export const getTransactionOverviewRoute = (
  transactionId: number,
  transactionCode?: string,
  budgetManagerCode?: string,
) => {
  const queryParams = queryString.stringify(
    { transaction_code: transactionCode, code: budgetManagerCode },
    { skipNull: true, skipEmptyString: true },
  );
  return `${compile(SCREENS.TRANSACTION_OVERVIEW)({ transaction_id: transactionId })}?${queryParams}`;
};

export const getTransactionBookingScreenRoute = (
  transactionCode: string,
  dispatch?: AppDispatch,
  bookingParameters?: BookingParameters,
  cartPayload?: SetUpCartPayload,
) => {
  if (dispatch) {
    if (bookingParameters) {
      dispatch(setInitialBookingParameters(bookingParameters));
    }
    if (cartPayload) {
      dispatch(setUpCart(cartPayload));
    }
  }
  return compile(SCREENS.TRANSACTION_BOOKING_SCREEN)({
    transaction_code: transactionCode,
  });
};

export const getProjectRoute = (projectId: number, code?: string) => {
  return `${compile(SCREENS.PROJECT)({ project_id: projectId })}${code ? `?code=${code}` : ""}`;
};

export const getIndividualProjectOverviewRoute = (
  projectId: number,
  scheduledProjectId: number | null,
  code?: string,
) => {
  if (!scheduledProjectId) {
    return getProjectRoute(projectId, code);
  }

  const queryParams = queryString.stringify(
    { project: projectId, code },
    { skipNull: true, skipEmptyString: true },
  );
  return `${compile(SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN)({ scheduled_project_id: scheduledProjectId })}${queryParams ? `?${queryParams}` : ""}`;
};

export const getProjectAPIRoute = (projectId: number, code?: string) => {
  return `${compile(GET_PROJECT)({ project_id: projectId })}${code ? `?code=${code}` : ""}`;
};

export const getProjectHistoryRoute = (
  projectId: number,
  page?: number,
  code?: string,
) => {
  const queryParams = queryString.stringify(
    { page, code },
    { skipNull: true, skipEmptyString: true },
  );
  return `${compile(GET_PROJECT_HISTORY)({ project_id: projectId })}${queryParams ? `?${queryParams}` : ""}`;
};

export const getProjectFilesRoute = (
  projectId: number,
  fileVersionIds: number[],
  code?: string,
) => {
  const params = queryString.stringify(
    { file_version_ids: fileVersionIds, code },
    { arrayFormat: "comma", skipEmptyString: true, skipNull: true },
  );
  return `${compile(GET_PROJECT_FILES)({ project_id: projectId })}?${params}`;
};

export const getMusoSelectionScreenRoute = (tab: MusoSelectionTab) => {
  return compile(SCREENS.MUSO_SELECTION_SCREEN)({ tab });
};

export const getProjectOverviewRoute = (projectId?: number, code?: string) => {
  if (!projectId) return "";
  return `${compile(SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN)({ scheduled_project_id: projectId })}${code ? `?code=${code}` : ""}`;
};

export const getStudioScreenRoute = (studioUsername: string | undefined) => {
  if (!studioUsername) return "";
  return compile(SCREENS.STUDIO_SCREEN)({ username: studioUsername });
};

export const getStudioRoomScreenRoute = (
  studioUsername: string | undefined,
  studioRoomId: number,
) => {
  if (!studioUsername || !studioRoomId) return "";
  return compile(SCREENS.STUDIO_ROOM)({
    username: studioUsername,
    room_id: studioRoomId,
  });
};

export const getProfileScreenRoute = (username: string) => {
  if (!username) return "";
  return compile(SCREENS.PROFILE_SCREEN)({ username });
};

export const getRecordingSessionRoute = (projectId: number) => {
  return compile(SCREENS.RECORDING_SESSION)({
    project_id: projectId,
  });
};

export const getLoggedOutSessionDetailsRoute = (
  projectId: string,
  code: string,
) => {
  const params = queryString.stringify(
    { code },
    { skipNull: true, skipEmptyString: true },
  );
  const paramString = params ? `/?${params}` : "";
  return `${compile(SCREENS.LOGGED_OUT_SESSION_DETAILS)({
    project_id: projectId,
  })}${paramString}`;
};

export const getInProgressProjectRouteWithCode = (
  projectId: number,
  code: string,
) => {
  if (!projectId || !code) return "";
  return compile(SCREENS.PROJECT)({ project_id: projectId }) + `?code=${code}`;
};

export const getProjectsRoute = (
  tab: "active" | "pending" | "today" | "sessions" = "active",
) => {
  return compile(SCREENS.PROJECTS)({ tab });
};

export const getShareTransactionAPIRoute = (transactionId: number) =>
  compile(SHARE_TRANSACTION)({ transaction_id: transactionId });

interface MyBookingsParams {
  [RecordingSessionQueryParams.PROJECT_ID]?: number;
  [RecordingSessionQueryParams.BOOKING_ID]?: number;
  [BookingQueryParamKeys.ScheduledProjectId]?: number;
}

export type BookingRouteTabs = "sessions" | "projects" | "purchases";

export const getMyBookingsRoute = (
  tab: BookingRouteTabs,
  bookingParams: MyBookingsParams = {},
) => {
  let result = compile(SCREENS.BOOKINGS)({ tab });
  if (Object.entries(bookingParams).length > 0) {
    const paramString = queryString.stringify(bookingParams, {
      skipEmptyString: true,
      skipNull: true,
    });

    result += `?${paramString}`;
  }
  return result;
};

export const getSessionDetailsRoute = (projectId: number) => {
  return (
    compile(SCREENS.BOOKINGS)({ tab: "sessions" }) + `?project=${projectId}`
  );
};

export const getDashboardRoute = () => {
  return compile(SCREENS.DASHBOARD)();
};

export const getAdminDashboardRoute = () => {
  return compile(SCREENS.ADMIN_DASHBOARD)();
};

export const getHomepageRoute = () => {
  return compile(SCREENS.HOME_SCREEN)();
};

export const getMyLibraryRoute = () => {
  return compile(SCREENS.MY_LIBRARY)();
};

export const getSearchScreenWithPromocodeAttached = (promoCode: string) => {
  return SCREENS.SEARCH + `?promocode=${promoCode}`;
};

export const getMessagesRoute = (params: {
  status?: boolean;
  channel?: string;
}) => {
  const queryParams: Record<string, string> = {};
  if (params.status) {
    queryParams.page = PAGE_VALUES_TO_QUERY_VALUES[PAGE_VALUES.STATUS];
  } else if (params.channel) {
    queryParams.channel = params.channel;
  }
  const queryParamsStringified = queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
  });
  return SCREENS.MESSAGES + Object.keys(queryParams).length
    ? `?${new URLSearchParams(queryParamsStringified).toString()}`
    : "";
};

export const getReleaseScreenRoute = (releaseId: number) => {
  if (!releaseId) return "";
  return compile(SCREENS.RELEASE_DETAIL)({ release_id: releaseId });
};

export const getAddSubscriptionRoute = (refundFees?: boolean) => {
  return SCREENS.ADD_SUBSCRIPTION + `?refundfees=${refundFees}`;
};

export const getCommunityRoute = () => {
  return SCREENS.COMMUNITY;
};

export const getReleaseCheckoutScreenRoute = (transactionCode: string) => {
  return compile(SCREENS.RELEASE_CHECKOUT)({
    transaction_code: transactionCode,
  });
};

export const getProductTaxCalculationRoute = (transactionCode: string) => {
  return compile(CALCULATE_PRODUCT_TAX)({
    transaction_code: transactionCode,
  });
};

export interface PaymentRedirectParams {
  transaction_id: string;
  transaction_code: string;
  project_title?: string;
  scheduled_project_id?: string;
  redirect_status?: string;
  payment_intent_client_secret?: string;
  purchase_type?: "release" | "projects" | "sessions";
}

export const getPaymentRedirectURL = (
  paymentOption: FinancialMethod,
  paramObject: PaymentRedirectParams,
): string => {
  const params = queryString.stringify(paramObject, {
    skipNull: true,
    skipEmptyString: true,
  });
  switch (paymentOption) {
    case FinancialMethod.AFFIRM:
      return `${window.location.origin}/${PaymentRedirectType.AFFIRM}payment/?${params}`;
    case FinancialMethod.CASH_APP:
      return `${window.location.origin}/${PaymentRedirectType.CASH_APP}payment/?${params}`;
    case FinancialMethod.KLARNA:
      return `${window.location.origin}/${PaymentRedirectType.KLARNA}payment/?${params}`;
    default:
      return window.location.href;
  }
};
