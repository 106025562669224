import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import ViewSlider, { ViewProps } from "react-view-slider";
import { useFetchProductsByReleaseIdQuery } from "../../../api/products/hooks/usefetchProductsByReleaseIdQuery";
import { useFetchFeaturedArtistReleaseQuery } from "../../../api/releases/hooks/useFetchFeaturedReleaseQuery";
import {
  artistReleaseTabViewAtom,
  ReleasesTabView,
  selectedReleaseIdAtom,
} from "../../../atoms/profileScreen/artistReleaseTabView";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { FeaturedReleaseProducts } from "../FeaturedReleaseProducts/FeaturedReleaseProducts";
import { ProductCheckoutFooter } from "../ProductCheckoutFooter/ProductCheckoutFooter";
import { AddReleaseView } from "./AddReleaseView";
import { ReleaseDetailView } from "./ReleaseDetailView";
import { ReleaseOverview } from "./ReleaseOverview";

export const RELATIVE_DESKTOP_BREAKPOINT = 616.75;

export const ArtistReleaseTabView = () => {
  const [currentView, setView] = useAtom(artistReleaseTabViewAtom);
  const [selectedReleaseId, setSelectedReleaseId] = useAtom(
    selectedReleaseIdAtom,
  );
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const { data: release, isPending: isReleaseLoading } =
    useFetchFeaturedArtistReleaseQuery(userData?.id);
  const {
    data: featuredProducts,
    isSuccess: isFeaturedProductsFetchingSuccess,
  } = useFetchProductsByReleaseIdQuery(release?.id);

  useEffect(() => {
    return () => {
      setSelectedReleaseId(null);
      setView(ReleasesTabView.overview);
    };
  }, [setSelectedReleaseId, setView]);

  const onReleaseClick = useCallback(
    (releaseId?: number) => {
      setSelectedReleaseId(releaseId);
      setView(ReleasesTabView.detail);
    },
    [setSelectedReleaseId, setView],
  );

  const renderView = ({ index }: ViewProps) => {
    switch (index) {
      case ReleasesTabView.overview:
        return (
          <ReleaseOverview onReleaseClick={onReleaseClick}>
            <FeaturedArtistRelease
              release={release}
              onClick={() => {
                onReleaseClick(release?.id);
              }}
              isReleaseLoading={isReleaseLoading}
            />
            <FeaturedReleaseProducts featuredReleaseId={release?.id} />
          </ReleaseOverview>
        );

      case ReleasesTabView.detail:
        if (!selectedReleaseId) {
          return <AddReleaseView />;
        }
        return (
          <ReleaseDetailView
            hidePurchaseButton={false}
            navigateBack={() => {
              setView(ReleasesTabView.overview);
            }}
            releaseId={selectedReleaseId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box minHeight={1050}>
      <ViewSlider
        onSlideTransitionEnd={() => {
          if (currentView === ReleasesTabView.overview) {
            setSelectedReleaseId(null);
          }
        }}
        numViews={2}
        keepViewsMounted
        renderView={renderView}
        animateHeight
        activeView={currentView}
      />
      {isFeaturedProductsFetchingSuccess &&
        featuredProducts &&
        featuredProducts.length > 0 && <ProductCheckoutFooter />}
    </Box>
  );
};
