import { useLocation } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import {
  isCurrentUserEngineerOrStudioManagerSelector,
  isUserVerifiedAdminSelector,
} from "../../../store/selectors/userInfoSelectors";
import { MyLibraryMenu } from "../Navigation/MyLibraryMenu/MyLibraryMenu";
import { NavigationRow } from "../Navigation/NavigationRow/NavigationRow";
import { NavLoginContainer } from "../Navigation/NavLoginContainer/NavLoginContainer";
import { NavProfileMenu } from "../Navigation/NavProfileMenu/NavProfileMenu";
import { NavSearchBar } from "../Navigation/NavSearchBar/NavSearchBar";
import "./LoggedInHeaderContent.css";

export const LoggedInHeaderContent = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const isEngineerOrStudioManager = useAppSelector(
    isCurrentUserEngineerOrStudioManagerSelector,
  );
  const { isMobile, isTablet } = useMediaQueryBreakpoint();
  const { pathname } = useLocation();
  const userIsAdmin = useAppSelector(isUserVerifiedAdminSelector);

  const showSearchBar =
    pathname !== SCREENS.SEARCH &&
    pathname !== SCREENS.SIGNED_OUT_HOME_SCREEN &&
    pathname !== SCREENS.SIGN_IN_SCREEN &&
    pathname !== SCREENS.SIGN_UP_SCREEN &&
    pathname !== SCREENS.PAGINATED_ENGINEERS &&
    pathname !== SCREENS.PAGINATED_STUDIOS &&
    pathname !== SCREENS.PAGINATED_STUDIOS_ALT &&
    !isMobile;

  return (
    <div className="app-bar-content-container" style={{ marginLeft: "auto" }}>
      <div className="center-nav-section">
        <NavigationRow
          isAdmin={userIsAdmin}
          isEngineerOrStudioManager={isEngineerOrStudioManager}
          user={user}
          path={pathname}
        />
        {showSearchBar && <NavSearchBar />}
      </div>
      <div className="logged-in-header-container">
        {!isMobile && user && (
          <>
            <MyLibraryMenu />
            <NavProfileMenu user={user} />
          </>
        )}
        {!user && <NavLoginContainer showSignUpButton={!isTablet} />}
      </div>
    </div>
  );
};
