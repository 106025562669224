import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { assertEngineEarsErrorType, displayEngineEarsError } from "../helpers";
import { ProductTransaction } from "./models";
import { markProductTransactionAsPaid } from "./productTransactionsAPI";

interface PayloadProps {
  transactionCode: string;
  taxCalculationId?: string;
}

export const useMarkProductTransactionAsPaidMutation = () => {
  return useMutation({
    mutationFn: async (payload: PayloadProps) => {
      const response = await markProductTransactionAsPaid(
        payload.transactionCode,
        { tax_calculation_id: payload.taxCalculationId },
      );
      if (!response.success) {
        throw response.resultJson as EngineEarsError;
      }
      return response.resultJson as ProductTransaction;
    },
    mutationKey: [QUERY_KEYS.MARK_PRODUCT_TRANSACTION_AS_PAID],
    onError: (error: EngineEarsError) => {
      if (assertEngineEarsErrorType(error)) {
        displayEngineEarsError(error);
      }
    },
  });
};
