import { getEntityPhotoImageProps } from "../../stories/screens/ProfileScreen/utils";
import {
  getArtistFromProject,
  getEngineerFromProject,
  isProjectInReviewStepOrBeyond,
} from "../../utils/projectUtils";
import { porfolioCoverArtPathPrefix } from "../utils";
import { getDisplayableNameForUser } from "../utils/entityUtils";
import { MusoCredit } from "./muso";
import { PortfolioFeatureData } from "./portfolio";
import {
  APIMasteringProject,
  APIMixingProject,
  Project,
  ProjectById,
  ProjectType,
} from "./project";
import { ReleaseTrack } from "./release";

export enum PlaylistTrackType {
  Project,
  ProjectById,
  PortfolioFeatureData,
  ArtistRelease,
  Muso,
}

export interface PlaylistTrack {
  id: number;
  fileVersionId?: number;
  type: PlaylistTrackType;
  title: string;
  artistDisplayName: string;
  artistUsername: string;
  engineerUsername: string;
  artworkSource?: string;
  serviceType?: ProjectType;
  engineerDisplayName?: string;
  spotify_preview_url?: string;
  paymentRequiredForPlayback?: boolean;
}

const convertProjectByIdToPlayListTrack = (
  project: ProjectById,
): PlaylistTrack => {
  const artistUser = getArtistFromProject(project);
  const engineerUser = getEngineerFromProject(project);
  return {
    id: project.id,
    type: PlaylistTrackType.ProjectById,
    title: project.title,
    artistDisplayName: getDisplayableNameForUser(artistUser),
    artistUsername: artistUser?.username || "",
    engineerUsername: engineerUser?.username || "",
    engineerDisplayName: getDisplayableNameForUser(engineerUser),
  };
};

export const convertProjectToPlayListTrack = (
  project: Project | ProjectById,
): PlaylistTrack => {
  if ("artist" in project) {
    const projectWithArtist = project as Project;
    return {
      id: project.id,
      type: PlaylistTrackType.Project,
      title: project.title,
      artistDisplayName: getDisplayableNameForUser(projectWithArtist.artist),
      artistUsername: projectWithArtist.artist?.username || "",
      engineerUsername: projectWithArtist.engineer?.username || "",
      engineerDisplayName: getDisplayableNameForUser(project.engineer),
    };
  } else {
    const projectWithId = project as ProjectById;
    return convertProjectByIdToPlayListTrack(projectWithId);
  }
};

export const convertPortfolioFeatureDataToPlayListTrack = (
  portfolioFeatureData?: PortfolioFeatureData,
): PlaylistTrack => {
  if (!portfolioFeatureData) {
    return {
      id: 0,
      type: PlaylistTrackType.PortfolioFeatureData,
      title: "",
      artistDisplayName: "",
      artistUsername: "",
      engineerUsername: "",
      engineerDisplayName: "",
    };
  }
  const { muso_credit, featured_track_artwork } = portfolioFeatureData;
  const source = featured_track_artwork?.path
    ? porfolioCoverArtPathPrefix + featured_track_artwork?.path
    : undefined;
  const externalSource = muso_credit?.album_art;
  const artworkSource = source || externalSource;
  return {
    id: portfolioFeatureData.id || 0,
    type: PlaylistTrackType.PortfolioFeatureData,
    title: portfolioFeatureData.title,
    artistDisplayName: portfolioFeatureData.artist,
    artistUsername: "",
    engineerUsername: portfolioFeatureData.user?.username || "",
    engineerDisplayName: getDisplayableNameForUser(portfolioFeatureData?.user),
    artworkSource: artworkSource,
  };
};

export const convertMusoCreditToPlayListTrack = (
  credit?: MusoCredit,
  engineerDisplayName?: string,
): PlaylistTrack => {
  if (!credit) {
    return {
      id: 0,
      type: PlaylistTrackType.Muso,
      title: "",
      artistDisplayName: "",
      artistUsername: "",
      engineerUsername: "",
      engineerDisplayName: "",
      spotify_preview_url: "",
    };
  }
  const { id, track_title, album_art, artist } = credit;
  const artworkSource = album_art;
  return {
    id: id || 0,
    type: PlaylistTrackType.Muso,
    title: track_title,
    artistDisplayName: artist,
    artistUsername: "",
    engineerUsername: engineerDisplayName ?? "",
    engineerDisplayName: engineerDisplayName ?? "",
    artworkSource: artworkSource,
    spotify_preview_url: credit.spotify_preview_url ?? "",
  };
};

const isValidProjectForPlaylist = (
  project: Project | ProjectById,
): project is Project => {
  if ("step" in project) {
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      (project as Project).step,
    );
  }
  if ("mastering_project" in project) {
    const masteringProject = (project as ProjectById)
      ?.mastering_project as APIMasteringProject;
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      masteringProject?.step ?? 0,
    );
  }
  if ("mixing_project" in project) {
    const mixingProject = (project as ProjectById)
      ?.mixing_project as APIMixingProject;
    return isProjectInReviewStepOrBeyond(
      project.service_type,
      mixingProject?.step || 0,
    );
  }
  return false;
};

export const convertProjectsToPlayListTracks = (
  projects: Project[] | ProjectById[],
): PlaylistTrack[] => {
  if (
    Array.isArray(projects) &&
    (projects as Project[]).every((project) => "artist" in project)
  ) {
    return (projects as Project[]).reduce(
      (acc: PlaylistTrack[], project: Project) => {
        if (isValidProjectForPlaylist(project)) {
          acc.push(convertProjectToPlayListTrack(project));
        }
        return acc;
      },
      [],
    );
  } else {
    return (projects as ProjectById[]).reduce(
      (acc: PlaylistTrack[], project: ProjectById) => {
        if (isValidProjectForPlaylist(project)) {
          acc.push(convertProjectByIdToPlayListTrack(project));
        }
        return acc;
      },
      [],
    );
  }
};

export const convertReleaseTrackToPlaylistTrack = (
  releaseTrack: ReleaseTrack,
) => {
  const artist = releaseTrack.release.artist_user;
  const artistDisplayName = getDisplayableNameForUser(artist);
  const artistUsername = artist?.username || "";
  const paymentRequiredForPlayback =
    releaseTrack.release.payment_required_for_playback &&
    !releaseTrack.release_track_purchased_by_current_user;
  return {
    id: releaseTrack.id,
    artworkSource: getEntityPhotoImageProps(
      releaseTrack.release.release_cover,
      true,
    ).src,
    type: PlaylistTrackType.ArtistRelease,
    title: releaseTrack.title,
    artistDisplayName,
    artistUsername,
    engineerUsername: "",
    engineerDisplayName: "",
    fileVersionId: releaseTrack.file_version_id ?? undefined,
    paymentRequiredForPlayback: paymentRequiredForPlayback,
  } as PlaylistTrack;
};

export const convertArtistReleaseToPlaylistTrack = (
  releaseTracks: ReleaseTrack[],
): PlaylistTrack[] => {
  return releaseTracks.map(convertReleaseTrackToPlaylistTrack);
};

export const convertMusoCreditsToPlaylistTrack = (
  musoCredits: MusoCredit[],
  engineerDisplayName?: string,
): PlaylistTrack[] => {
  return musoCredits
    .filter((credit) => credit.spotify_preview_url)
    .map((credit) =>
      convertMusoCreditToPlayListTrack(credit, engineerDisplayName),
    );
};
