import { Box, Skeleton, Typography } from "@mui/material";
import { getYear } from "date-fns";
import { useMemo } from "react";
import { useFetchReleaseDetailsQuery } from "../../../api/releases/hooks/useFetchReleaseDetailsQuery";
import { useFetchReleaseTracksQuery } from "../../../api/releases/hooks/useFetchReleaseTracksQuery";
import { useAppSelector } from "../../../store/hooks";
import { Release } from "../../../store/models/release";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { EditReleaseButton } from "../FeaturedArtistRelease/EditReleaseButton";
import { ReleasePlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import {
  ReleaseItemDetailsContainer,
  ReleaseListContainerStyle,
  ReleaseListItemContainer,
} from "./ReleaseList.styles";
import { ReleaseListItemCoverPhoto } from "./ReleaseListItemCoverPhoto";

interface ReleaseListItemProps {
  release: Release;
  onReleaseClick: (releaseId?: number) => void;
}

const ReleaseListItem = ({ release, onReleaseClick }: ReleaseListItemProps) => {
  const { data, isPending } = useFetchReleaseTracksQuery(release.id);
  const { data: releaseDetails, isLoading: isReleaseDetailsInitialLoading } =
    useFetchReleaseDetailsQuery(release?.id);
  const { currentTrackIndex, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );
  const songIndex = useMemo(() => {
    if (playlistId === release.id) {
      return currentTrackIndex;
    }
    return 0;
  }, [playlistId, currentTrackIndex, release.id]);

  const selectedTrack = data[songIndex];

  return (
    <ReleaseListItemContainer
      onClick={() => {
        onReleaseClick(release.id);
      }}
    >
      <ReleaseListItemCoverPhoto releaseCover={release.release_cover}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReleasePlayPauseButton releaseId={release.id} />
        </Box>
      </ReleaseListItemCoverPhoto>
      <ReleaseItemDetailsContainer>
        <EditReleaseButton releaseId={release.id}>
          <Typography
            textAlign={"left"}
            lineHeight={"20px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            flexWrap={"wrap"}
            maxWidth={"150px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            fontSize={"16px"}
          >
            {release.title}
          </Typography>
        </EditReleaseButton>

        <Text variant={TextStyleVariant.P3}>
          {release.artist_display_name ||
            `@${getDisplayableNameForUser(release.artist_user)}`}
          {release.published_at
            ? ` (${getYear(new Date(release.published_at))})`
            : ""}
        </Text>
        {/* Temporarily hide play count */}
        {/* {isReleaseDetailsInitialLoading ? (
          <Skeleton style={{ marginTop: 8 }} height={5} width={100} />
        ) : (
          <Text
            style={{
              color: theme.palette.secondary.light,
              marginTop: 8,
            }}
            variant={TextStyleVariant.P4}
          >{`${releaseDetails?.total_play} plays`}</Text>
        )} */}
      </ReleaseItemDetailsContainer>
    </ReleaseListItemContainer>
  );
};

const LoadingReleaseListItem = () => {
  return (
    <ReleaseListItemContainer>
      <Skeleton variant={"rounded"} width={"64px"} height={"64px"} />
      <ReleaseItemDetailsContainer>
        <Skeleton height={10} width={200} />
        <Skeleton height={5} width={200} />
      </ReleaseItemDetailsContainer>
    </ReleaseListItemContainer>
  );
};

const ReleaseListItemSkeleton = () => {
  return (
    <ReleaseListContainerStyle>
      {Array.from({ length: RELEASES_PAGE_SIZE }).map((_, idx) => (
        <LoadingReleaseListItem key={idx} />
      ))}
    </ReleaseListContainerStyle>
  );
};

interface ReleaseListProps {
  releases: Release[];
  isPending: boolean;
  onReleaseClick: (releaseId?: number) => void;
}

export const ReleaseList = ({
  releases,
  isPending,
  onReleaseClick,
}: ReleaseListProps) => {
  if (isPending) {
    return <ReleaseListItemSkeleton />;
  }
  return (
    <ReleaseListContainerStyle>
      {releases.map((release) => (
        <ReleaseListItem
          key={release.id}
          release={release}
          onReleaseClick={onReleaseClick}
        />
      ))}
    </ReleaseListContainerStyle>
  );
};

// This value must be keep in sync with BE
// engineears/release/release_services/get_release_service.py
export const RELEASES_PAGE_SIZE = 9;
