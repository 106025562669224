import { useSortable } from "@dnd-kit/sortable";
import { faArrowUpArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { ReleaseTrack } from "../../../store/models/release";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { AddReleaseTrackToCartButton } from "../AddReleaseTrackToCartButton/AddReleaseTrackToCartButton";
import { RowDraggerButton } from "../ProjectOverviewTable/NewProjectsTable.styles";
import { ReleaseTrackPlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import { ReleaseTrackOptions } from "./ReleaseTrackOptions";

export enum ReleaseTrackTableRowId {
  ReorderTab = "reorder-tab",
  Title = "title",
  PlaySection = "play_section",
  Price = "price",
  // PlayCount = "play_count",
  DropDown = "drop_down",
}

interface ExtendedCellContext<TData, TValue>
  extends CellContext<TData, TValue> {
  unitPrice?: string | null;
  selectedProductId?: number;
  isArtistOnRelease?: boolean;
  openFeedbackModal?: () => void;
}

const RowDragHandleCell = ({ rowId }: { rowId: number }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <RowDraggerButton {...attributes} {...listeners}>
      <FontAwesomeIcon icon={faArrowUpArrowDown} size="sm" />
    </RowDraggerButton>
  );
};

export const RELEASE_TRACK_MOBILE_TABLE_COLUMNS = [
  ReleaseTrackTableRowId.ReorderTab,
  ReleaseTrackTableRowId.Title,
  ReleaseTrackTableRowId.PlaySection,
  ReleaseTrackTableRowId.Price,
];

const columnHelper = createColumnHelper<ReleaseTrack>();

export const columnDefinitionForReleaseTracks: ColumnDef<ReleaseTrack>[] = [
  columnHelper.display({
    size: 48,
    id: ReleaseTrackTableRowId.ReorderTab,
    header: () => null,
    cell: (cell: ExtendedCellContext<ReleaseTrack, unknown>) => {
      const { isArtistOnRelease } = cell;
      const { original } = cell.row;
      if (!isArtistOnRelease) {
        return null;
      }
      return <RowDragHandleCell rowId={original.id} />;
    },
  }),
  columnHelper.display({
    id: ReleaseTrackTableRowId.Title,
    header: () => "Title",
    size: 200,
    cell: ({ row: { original } }) => (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
        width={"100%"}
      >
        <Typography>{original.track_number + 1}</Typography>
        <Typography>{original.title}</Typography>
      </Box>
    ),
  }),
  columnHelper.display({
    id: ReleaseTrackTableRowId.PlaySection,
    header: () => null,
    size: 539,
    cell: ({ row: { original } }) => (
      <ReleaseTrackPlayPauseButton
        size={32}
        trackId={original.id}
        releaseId={original.release.id}
      />
    ),
  }),
  columnHelper.display({
    id: ReleaseTrackTableRowId.Price,
    header: () => "Price",
    size: 184,
    cell: (row: ExtendedCellContext<ReleaseTrack, unknown>) => {
      const { unitPrice, selectedProductId } = row;
      const {
        id: releaseTrackId,
        release_track_purchased_by_current_user,
        release: { id: releaseId },
      } = row.row.original;
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width={"100%"}
          justifyContent="space-between"
          gap={2}
        >
          {unitPrice && !release_track_purchased_by_current_user && (
            <Typography>
              {PennyDollarFormatter().format(parseFloat(unitPrice))}
            </Typography>
          )}
          {unitPrice && selectedProductId && (
            <AddReleaseTrackToCartButton
              releaseTrackId={releaseTrackId}
              unitPrice={unitPrice}
              productToGetPriceFromId={selectedProductId}
              releaseId={releaseId}
            />
          )}
        </Box>
      );
    },
  }),
  columnHelper.display({
    id: ReleaseTrackTableRowId.DropDown,
    size: 48,
    header: () => null,
    cell: (cell: ExtendedCellContext<ReleaseTrack, unknown>) => {
      const openFeedbackModal = cell.openFeedbackModal;
      if (!openFeedbackModal) {
        return null;
      }
      const { original } = cell.row;
      if (!original) {
        return null;
      }
      return (
        <ReleaseTrackOptions
          openFeedbackModal={openFeedbackModal}
          releaseTrack={original}
        />
      );
    },
  }),
];

export const getColumnSize = (
  headerId: ReleaseTrackTableRowId,
  isArtistOnRelease: boolean,
  isMobile: boolean,
  size: number,
  unitPrice?: string | null,
): number => {
  switch (headerId) {
    case ReleaseTrackTableRowId.Title:
      if (isMobile) {
        return 250;
      }
      return size;
    case ReleaseTrackTableRowId.ReorderTab:
      if (!isArtistOnRelease) {
        return 0;
      }
      return size;
    case ReleaseTrackTableRowId.Price:
      if (!unitPrice) {
        return 0;
      }
      return size;
    case ReleaseTrackTableRowId.PlaySection:
      if (isMobile) {
        return 50;
      }
      if (!unitPrice) {
        return size + 184;
      }
      return size;
    default:
      return size;
  }
};
