import { useReleaseDetails } from "../../../api/releases/hooks/useFetchReleaseDetails";
import { ReleaseCollaborator } from "../ReleaseCollaborator/ReleaseCollaborator";
import { Grid } from "@mui/material";

interface FeaturedReleaseCollaboratorsProps {
  releaseId?: number;
}

export const FeaturedReleaseCollaborators = ({
  releaseId,
}: FeaturedReleaseCollaboratorsProps) => {
  const {
    recordingEngineerCollaborators,
    mixingEngineerCollaborators,
    dolbyAtmosMixingEngineerCollaborators,
    masteringEngineerCollaborators,
    // Temporarily hide play count
    // playCount,
    isReleaseDetailsInitialLoading,
  } = useReleaseDetails(releaseId);

  return (
    <Grid mb={2} item display={"flex"} flexDirection={"column"} spacing={2}>
      <ReleaseCollaborator
        title={"Recording engineers:"}
        collaborators={recordingEngineerCollaborators}
        isLoadingCollaborators={isReleaseDetailsInitialLoading}
      />
      <ReleaseCollaborator
        title={"Mixing engineers:"}
        collaborators={mixingEngineerCollaborators}
        isLoadingCollaborators={isReleaseDetailsInitialLoading}
      />
      <ReleaseCollaborator
        title={"Dolby Atmos mixing engineers:"}
        collaborators={dolbyAtmosMixingEngineerCollaborators}
        isLoadingCollaborators={isReleaseDetailsInitialLoading}
      />
      <ReleaseCollaborator
        title={"Mastering engineers:"}
        collaborators={masteringEngineerCollaborators}
        isLoadingCollaborators={isReleaseDetailsInitialLoading}
      />
    </Grid>
  );
};
