import { ShoppingCartItem } from "../../../api/productTransactions/models";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";

interface ProductCheckoutButtonProps {
  disabled?: boolean;
  postLogin?: (_: ShoppingCartItem[]) => void;
}

export const ProductCheckoutButton = ({
  disabled,
}: ProductCheckoutButtonProps) => {
  const { cart, handleCheckout, isCheckingout } = useProductCart();
  return (
    <>
      <Button
        disabled={disabled}
        loading={isCheckingout}
        onClick={async () => {
          void handleCheckout(cart);
        }}
        variant={ButtonVariant.PRIMARY}
      >
        Checkout
      </Button>
    </>
  );
};
