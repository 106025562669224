import { faFolderMusic, faHouse } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { PROJECT_SCREEN_PATHS, SCREENS } from "../../../../routes";
import User from "../../../../store/models/user";
import { isUserListenerOnly } from "../../../../utils/utils";
import { Icon, IconType } from "../../../core-ui/components/Icon/Icon";
import { getNavButtonColor } from "../utils";
import {
  NavButton,
  NAV_BUTTON_VARIANT,
} from "./components/NavButton/NavButton";
import { NavMessagebutton } from "./components/NavMessageButton/NavMessageButton";
import { NavNotificationButton } from "./components/NavNotificationButton/NavNotificationButton";
import "./NavigationRow.css";

export interface NavigationRowProps {
  isAdmin: boolean;
  isEngineerOrStudioManager: boolean;
  path: string;
  user: User | undefined;
  renderInMobile?: boolean;
}

export const NavigationRow: React.FC<NavigationRowProps> = ({
  isAdmin,
  isEngineerOrStudioManager,
  path,
  user,
  renderInMobile,
}: NavigationRowProps) => {
  const isTooNarrowForNavText = useMediaQuery("(max-width:1115px)");
  const isSearchBarInHeader =
    path !== SCREENS.SEARCH && path !== SCREENS.SIGNED_OUT_HOME_SCREEN;
  const showIconOnly = isSearchBarInHeader || isTooNarrowForNavText;
  const showDashboardButton = isEngineerOrStudioManager || isAdmin;
  const dashboardPath = isAdmin ? SCREENS.ADMIN_DASHBOARD : SCREENS.DASHBOARD;

  const { pathname } = useLocation();

  if (!user) return null;

  return (
    <div
      className={`navigation-row ${renderInMobile ? "" : "navigation-row-top-nav"}`}
    >
      {showDashboardButton && (
        <NavButton
          variant={NAV_BUTTON_VARIANT.LINK}
          frontIcon={
            <FontAwesomeIcon
              color={getNavButtonColor([dashboardPath], pathname)}
              icon={faHouse}
              size="xl"
            />
          }
          message="Dashboard"
          path={dashboardPath}
          showIconOnly={showIconOnly}
          color={getNavButtonColor([dashboardPath], pathname)}
        />
      )}
      <NavNotificationButton
        iconColor={getNavButtonColor([SCREENS.NOTIFICATIONS], pathname)}
        showIconOnly={showIconOnly}
        user={user}
      />
      <NavButton
        variant={NAV_BUTTON_VARIANT.LINK}
        frontIcon={
          <FontAwesomeIcon
            color={getNavButtonColor(PROJECT_SCREEN_PATHS, pathname)}
            icon={faFolderMusic}
            size="xl"
          />
        }
        message="Bookings"
        path={
          isUserListenerOnly(user)
            ? SCREENS.MY_LIBRARY
            : SCREENS.PROJECTS_NO_TAB
        }
        showIconOnly={showIconOnly}
        color={getNavButtonColor(PROJECT_SCREEN_PATHS, pathname)}
      />
      <NavMessagebutton
        iconColor={getNavButtonColor([SCREENS.MESSAGES], pathname)}
        showIconOnly={showIconOnly}
        user={user}
      />
      <NavButton
        variant={NAV_BUTTON_VARIANT.LINK}
        frontIcon={
          <Icon
            fill={getNavButtonColor([SCREENS.COMMUNITY], pathname)}
            height="21"
            type={IconType.Group}
            viewBox="0 0 24 21"
          />
        }
        message="Community"
        path={SCREENS.COMMUNITY}
        showIconOnly={showIconOnly}
        color={getNavButtonColor([SCREENS.COMMUNITY], pathname)}
      />
    </div>
  );
};
