import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useProductCart } from "../../../atoms/products/hooks/useProductCart";
import { useProductCartTotals } from "../../../atoms/products/hooks/useProductCartTotals";
import { useAppSelector } from "../../../store/hooks";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { ProductCheckoutButton } from "./ProductCheckoutButton";
import {
  ProductCheckoutFooterContainer,
  ProductCheckoutFooterTextContainer,
} from "./ProductCheckoutFooter.styles";

export const ProductCheckoutFooter = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);
  const { handleClearCart } = useProductCart();
  const [totalCost, songsInCart] = useProductCartTotals();

  useEffect(() => {
    if (!pendingAction) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }
    pendingAction();
    setPendingAction(null);
  }, [isAuthenticated, pendingAction]);

  return (
    <ProductCheckoutFooterContainer>
      <ProductCheckoutFooterTextContainer>
        <Text
          variant={TextStyleVariant.P2}
        >{`${songsInCart} song(s) added to cart`}</Text>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Text variant={TextStyleVariant.P2} bold>
          {`Total: ${PennyDollarFormatter().format(totalCost)}`}
        </Text>
      </ProductCheckoutFooterTextContainer>
      <Button
        variant={ButtonVariant.OUTLINED}
        onClick={() => {
          handleClearCart();
        }}
        disabled={songsInCart === 0}
      >
        Clear Cart
      </Button>
      <ProductCheckoutButton disabled={songsInCart === 0} />
    </ProductCheckoutFooterContainer>
  );
};
