/* eslint-disable import/no-unused-modules */
import { Box, Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { EmptyContainer } from "../../screens/ProfileScreen/ProfileScreen.styles";
import { ProductCard, ProductCardProps } from "../ProductCard/ProductCard";
import {
  ProductBodyContainer,
  ProductCardContainer,
} from "../ProductCard/ProductCard.styles";
import { ProductCardsListContainer } from "./ProductCardsList.styles";
import { ProductDetailsModalWrapper } from "./ProductDetailsModalWrapper";

type ProductProps = Omit<ProductCardProps, "onShowMore">;

export interface ProductCardsListProps {
  products: ProductProps[];
  loading: boolean;
}

export const ProductCardsList = ({
  products,
  loading,
}: ProductCardsListProps) => {
  const [selectedProductId, setSelectedProductId] = useState<number | "">();

  const renderProductCards = () => {
    if (loading) {
      return (
        <ProductCardsListContainer>
          {Array.from({ length: 4 }).map((_, index) => (
            <LoadingProductCard key={index} />
          ))}
        </ProductCardsListContainer>
      );
    }

    if (products.length === 0) {
      return (
        <EmptyContainer>
          <Text>Featured Bundles Coming Soon!</Text>
        </EmptyContainer>
      );
    }

    return (
      <ProductCardsListContainer>
        {products.map((product) => (
          <ProductCard
            key={product.productId}
            {...product}
            onShowMore={() => {
              setSelectedProductId(product.productId);
            }}
          />
        ))}
      </ProductCardsListContainer>
    );
  };

  const productsOptions = useMemo(() => {
    return products.map((product) => ({
      label: `${product.totalPrice} | ${product.name}`,
      value: product.productId,
    }));
  }, [products]);

  const selectedProduct = useMemo(() => {
    if (selectedProductId) {
      return products.find(
        (product) => product.productId === selectedProductId,
      );
    }

    return undefined;
  }, [products, selectedProductId]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
      <Text variant={TextStyleVariant.S2}>Featured bundles</Text>
      {renderProductCards()}
      {selectedProduct && (
        <ProductDetailsModalWrapper
          releaseId={selectedProduct.releaseId}
          header={selectedProduct.name}
          description={selectedProduct.description}
          coverPhoto={selectedProduct.coverPhoto}
          name={selectedProduct.name}
          totalPrice={selectedProduct.totalPrice}
          productId={selectedProduct.productId}
          availableQuantity={selectedProduct.availableQuantity}
          purchaseLimit={selectedProduct.purchaseLimit}
          open={true}
          options={productsOptions}
          onChangeProduct={(productId) => {
            setSelectedProductId(productId);
          }}
          productPurchasedByCurrentUser={
            selectedProduct.productPurchasedByCurrentUser
          }
          setOpen={(isOpen: boolean) => {
            if (!isOpen) {
              setSelectedProductId("");
            }
          }}
        />
      )}
    </Box>
  );
};

const LoadingProductCard = () => {
  return (
    <ProductCardContainer sx={{ height: "323px", flex: "0 0 215px" }}>
      <Skeleton
        sx={{ aspectRatio: 1, width: "100%", height: "unset" }}
        variant="rectangular"
      />
      <ProductBodyContainer>
        <Box sx={{ minHeight: "54px" }}>
          <Skeleton height={20} />
          <Skeleton height={15} />
        </Box>
        <Skeleton height={20} />
      </ProductBodyContainer>
    </ProductCardContainer>
  );
};
