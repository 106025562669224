import { atom, Getter, Setter } from "jotai";
import { atomWithLocation } from "jotai-location";
import { AuthFlows, AuthSteps, FLOW_STEPS } from "../../../constants/authSteps";
import { showBottomAndTopNavAtom } from "../../../atoms/navAtoms";
import pathnameAtom from "../../../atoms/location/pathnameAtom";
import { atomWithReset, RESET } from "jotai/utils";

const _searchQueryAtom = atomWithLocation();

interface ExpectedSearchParams {
  step?: string;
  flow?: string;

  [x: string]: unknown;
}

interface SetSearchParams<T> {
  key: keyof ExpectedSearchParams;
  value: T;
  clear?: boolean;
}

const setSearchParamsHelper = (
  get: Getter,
  set: Setter,
  params: SetSearchParams<string> | SetSearchParams<string>[],
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(prev.searchParams);

    const setParam = (param: SetSearchParams<string>) => {
      if (param.clear && !param.value) {
        newParams.delete(String(param.key));
      } else {
        newParams.set(String(param.key), param.value);
      }
    };

    if (Array.isArray(params)) {
      params.forEach(setParam);
    } else {
      setParam(params);
    }

    return { ...prev, searchParams: newParams, pathname: get(pathnameAtom) };
  });
};

// Write the current step to the URL
export const currentSignUpStepAtom = atom(
  AuthSteps.START_SIGNUP,
  (get, set, value: AuthSteps) => {
    const key: keyof ExpectedSearchParams = "step";
    setSearchParamsHelper(get, set, { key, value: String(value), clear: true });
    set(currentSignUpStepAtom, value);
  },
);

// Read the current step from the URL
export const readCurrentStepFromUrl = atom((get) => {
  const stepValue = get(_searchQueryAtom).searchParams?.get("step");
  if (!stepValue) {
    return AuthSteps.NONE;
  }

  const parsedStepValue = parseInt(stepValue);

  return parsedStepValue as AuthSteps;
});

// Write the current flow to the URL. Used when changing flows
export const currentFlowAtom = atom(
  AuthFlows.LOGIN,
  (get, set, value: AuthFlows) => {
    if (value === AuthFlows.NONE) {
      return;
    }

    const key: keyof ExpectedSearchParams = "flow";
    setSearchParamsHelper(get, set, { key, value: String(value), clear: true });

    set(currentFlowAtom, value);
    const flowSteps = FLOW_STEPS[value]?.steps.flat() || [];
    set(currentSignUpStepAtom, flowSteps[0]);
  },
);

// Read current flow from the URL
export const readCurrentFlowFromUrl = atom((get) => {
  const flowValue = get(_searchQueryAtom).searchParams?.get("flow");
  return flowValue ? (parseInt(flowValue) as AuthFlows) : AuthFlows.NONE;
});

// Controls modal
export const authModalOpenAtom = atom(false, (get, set, value: boolean) => {
  if (value === false) {
    set(_searchQueryAtom, (prev) => {
      const newParams = new URLSearchParams(prev.searchParams);
      // Clear only step and flow from the parameters
      newParams.delete("step");
      newParams.delete("flow");
      return { ...prev, searchParams: newParams, pathname: get(pathnameAtom) };
    });
    // Set showBottomAndTopNavAtom to true when authModalOpenAtom is set to false
    set(showBottomAndTopNavAtom, true);
  }
  set(authModalOpenAtom, value);
});

// phone number for phone login
export const phoneNumberAtom = atomWithReset("");
// phone number signup
export const phoneNumberRegisterAtom = atomWithReset(false);

// reset for phone number atoms
export const resetPhoneNumberAuthAtom = atom(null, (_, set) => {
  set(phoneNumberAtom, RESET);
  set(phoneNumberRegisterAtom, RESET);
});
