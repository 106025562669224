import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./MapDisplay.css";
import { SoundWaveLoader } from "../SoundWaveLoader/SoundWaveLoader";
import {
  CSSProperties,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Geocode from "react-geocode";
import { GOOGLE_API } from "../../../store/utils";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export interface MapDisplayProps {
  location?: google.maps.places.PlaceResult | string | null | undefined;
  options?: google.maps.MapOptions;
  revealLocation?: boolean;
  mapContainerStyle?: CSSProperties;
  apiLoader?: any;
  latitude?: number;
  longitude?: number;
}

const LIBRARIES = ["places"];

export const MapDisplay: FC<MapDisplayProps> = ({
  mapContainerStyle,
  location,
  options = {
    zoomControl: false,
    fullscreenControl: true,
    streetViewControl: false,
  },
  revealLocation = false,
  apiLoader = {
    googleMapsApiKey: GOOGLE_API,
    preventGoogleFontsLoading: true,
    libraries: LIBRARIES,
  },
  latitude,
  longitude,
}): ReactElement => {
  const { isLoaded } = useJsApiLoader(apiLoader);
  const { isDesktop } = useMediaQueryBreakpoint();
  const mapStyle = useMemo(() => {
    if (mapContainerStyle) return mapContainerStyle;
    return {
      width: !isDesktop ? "325px" : "450px",
      height: !isDesktop ? "300px" : "300px",
      borderRadius: "10px",
    };
  }, [isDesktop, mapContainerStyle]);

  const [stateCenter, setStateCenter] = useState({
    lat: latitude || 0,
    lng: longitude || 0,
  });
  if (!location || location.hasOwnProperty("name")) {
    <>
      <SoundWaveLoader height={250} width={250} />
    </>;
  }

  const findLatLong = useCallback(
    (location: google.maps.places.PlaceResult | string | null | undefined) => {
      let center = { lat: 0, lng: 0 };
      if (typeof location === "string") {
        Geocode.fromAddress(location)
          .then(
            (response: any) => {
              const { lat, lng } = response.results[0].geometry.location;
              center = {
                lat: lat,
                lng: lng,
              };
              if (!revealLocation) {
                center = {
                  lat: lat + (Math.random() * (0.01 - -0.01) + -0.01),
                  lng: lng + (Math.random() * (0.01 - -0.01) + -0.01),
                };
              }
            },
            (error: any) => {
              console.error(error);
            },
          )
          .then(() => {
            setStateCenter({
              lat: center.lat,
              lng: center.lng,
            });
          });
      } else if (
        location !== null &&
        location !== undefined &&
        location.geometry &&
        location.geometry.location
      ) {
        center = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
        };
        if (!revealLocation) {
          center = {
            lat:
              location.geometry!.location.lat() +
              (Math.random() * (0.01 - -0.01) + -0.01),
            lng:
              location.geometry!.location.lng() +
              (Math.random() * (0.01 - -0.01) + -0.01),
          };
        }
        setStateCenter({
          lat: center.lat,
          lng: center.lng,
        });
      }
    },
    [revealLocation],
  );

  useEffect(() => {
    if (location && stateCenter.lat === 0 && stateCenter.lng === 0) {
      findLatLong(location);
    }
  }, [location, findLatLong, stateCenter]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle}
      center={stateCenter}
      zoom={11}
      options={options}
    >
      {revealLocation && <Marker position={stateCenter} zIndex={1} />}
      {!revealLocation && (
        <Circle
          center={stateCenter}
          radius={1000}
          options={{
            fillColor: "#75c3f1",
            strokeColor: "#75c3f1",
            strokePosition: google.maps.StrokePosition.CENTER,
            fillOpacity: 0.3,
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};
