import queryString from "query-string";
import { EngineEarsError } from "../../store/actions/errorStore";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
  makeBackendPutCallWithJsonResponse,
} from "../../store/utils/fetch";
import {
  FETCH_PRODUCT_TRANSACTION_DETAILS,
  FETCH_PURCHASED_PRODUCTS,
  PAY_PRODUCT_TRANSACTION,
  PRODUCT_TRANSACTION_API,
  PRODUCT_TRANSACTION_CHECKOUT_ROUTE,
} from "../../store/utils/routes";
import { replaceRoutePlaceholders } from "../helpers";
import {
  ProductTransaction,
  ProductTransactionCreateArgs,
  ProductTransactionResponse,
  PurchasedProductTransactionOrderBy,
  PurchasedProductTransactionResponse,
  TransactionItemsGroupedByProduct,
} from "./models";

export const createProductTransaction = async (
  args: ProductTransactionCreateArgs,
) => {
  return await makeBackendPostCallWithJsonResponse<
    ProductTransactionResponse | EngineEarsError
  >(PRODUCT_TRANSACTION_CHECKOUT_ROUTE, args);
};

export const updateProductTransaction = async (transcationCode: string) => {
  return await makeBackendPutCallWithJsonResponse<
    ProductTransactionResponse | EngineEarsError
  >(`${PRODUCT_TRANSACTION_CHECKOUT_ROUTE}${transcationCode}/`, {});
};

export const fetchProductTransaction = async (transactionCode: string) => {
  const placeholders = { transaction_code: transactionCode };
  const updatedRoute = replaceRoutePlaceholders(
    PRODUCT_TRANSACTION_API,
    placeholders,
  );
  return await makeBackendGetCallWithJsonResponse<
    ProductTransactionResponse | EngineEarsError
  >(updatedRoute, "");
};

export const fetchProductTransactionDetails = async (
  transactionCode: string,
) => {
  const placeholders = { transaction_code: transactionCode };
  const updatedRoute = replaceRoutePlaceholders(
    FETCH_PRODUCT_TRANSACTION_DETAILS,
    placeholders,
  );
  return await makeBackendGetCallWithJsonResponse<
    TransactionItemsGroupedByProduct[] | EngineEarsError
  >(updatedRoute, "");
};

interface markProductTransactionAsPaidPayloadProps {
  tax_calculation_id?: string;
}

export const markProductTransactionAsPaid = async (
  transactionCode: string,
  payload: markProductTransactionAsPaidPayloadProps = {},
) => {
  const placeholders = { transaction_code: transactionCode };
  const updatedRoute = replaceRoutePlaceholders(
    PAY_PRODUCT_TRANSACTION,
    placeholders,
  );
  return await makeBackendPostCallWithJsonResponse<
    ProductTransaction | EngineEarsError
  >(updatedRoute, payload);
};

export const fetchPurchasedProductTransactions = async (
  searchQuery: string,
  page: number,
  orderBy: PurchasedProductTransactionOrderBy,
) => {
  const params = queryString.stringify(
    {
      search_query: searchQuery,
      page,
      order_by: orderBy,
    },
    { skipEmptyString: true, skipNull: true },
  );
  return await makeBackendGetCallWithJsonResponse<
    PurchasedProductTransactionResponse | EngineEarsError
  >(FETCH_PURCHASED_PRODUCTS, `?${params}`);
};
