import { faListMusic } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { SCREENS } from "../../../../routes";
import {
  NavButton,
  NAV_BUTTON_VARIANT,
} from "../NavigationRow/components/NavButton/NavButton";
import { getNavButtonColor } from "../utils";

export const MyLibraryMenu = () => {
  const { pathname } = useLocation();

  return (
    <NavButton
      variant={NAV_BUTTON_VARIANT.LINK}
      frontIcon={
        <FontAwesomeIcon
          color={getNavButtonColor([SCREENS.MY_LIBRARY], pathname)}
          icon={faListMusic}
          size="xl"
        />
      }
      message="Library"
      path={SCREENS.MY_LIBRARY}
      showIconOnly={true}
      color={getNavButtonColor([SCREENS.MY_LIBRARY], pathname)}
    />
  );
};
