import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { AuthFlows } from "../../../constants/authSteps";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  CloseButton,
  FormContainer,
  MainContentContainer,
  SignUpButtonsContainer,
  SignUpFooter,
  SignUpSocialButton,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { authModalOpenAtom, currentFlowAtom } from "./atoms";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useAppSelector } from "../../../store/hooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";

export const StartSignIn = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const theme = useTheme();
  const setFlow = useSetAtom(currentFlowAtom);
  const [, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const { handleGoogleClick, isGoogleLoginOpen } = useGoogleAuth();
  const { isDesktop } = useMediaQueryBreakpoint();

  return (
    <FormContainer>
      {isDesktop && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_start_signup_click_on_exit",
                {},
                user?.id,
              );
              setAuthModalOpen(false);
            }}
          />
        </CloseButton>
      )}
      <MainContentContainer>
        <SignUpButtonsContainer>
          <Text variant={TextStyleVariant.H6}>Sign in to your account</Text>
          <Text>
            {"Don't have an account?"}
            <Button
              style={{
                display: "inline",
                marginLeft: "4px",
                color: theme.palette.text.primary,
              }}
              variant={ButtonVariant.TEXT}
              onClick={() => {
                setFlow(AuthFlows.START_SIGNUP);
              }}
            >
              Sign Up
            </Button>
          </Text>
          <SignUpSocialButton
            loading={isGoogleLoginOpen}
            onClick={handleGoogleClick}
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
          >
            <img
              alt={"google logo icon"}
              src={GoogleLogoIcon}
              width={18}
              height={18}
            />
            Continue with Google
          </SignUpSocialButton>
          <Button
            fullWidth={true}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_start_signin_click_on_continue_with_phone_number",
                {},
                user?.id,
              );
              setFlow(AuthFlows.PHONE_ADD);
            }}
            variant={ButtonVariant.OUTLINED}
          >
            Continue with phone number
          </Button>
          <Button
            fullWidth={true}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_start_signin_click_on_continue_with_email",
                {},
                user?.id,
              );
              setFlow(AuthFlows.EMAIL_LOGIN);
            }}
          >
            Continue with email
          </Button>
        </SignUpButtonsContainer>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
